@tailwind base;
@tailwind components;
@tailwind utilities;

/*// <uniquifier>: Use a unique and descriptive class name*/

.inter-400 {
  font-family: 'Inter', sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings: 'slnt' 0;
}

html {
  overflow: hidden;
  height: 100vh;
}

body {
  height: 100vh;
}

#root {
  height: 100vh;
}
